export const CURRENT_CONFIG = {
  // license
  appId: '148773', // You need to go to the development website to apply.
  appKey: '3d1ded8d5f270874d05328bf0a7f12f', // You need to go to the development website to apply.
  appLicense:
    'gBjD9gJ0kZavQ2Kfm9TDk99UaDy2KcioM1cL86gK7gnVM9j5iDLJClugnOfZ6nT3/wMZP/RZisJkU/ADtly/0Xqnu3GuzXz4XR16vfFqT7rkT+fSXoO+jgBPaYmTVFWBX1mhQduVcx6GJf6n1AqFRuAmO0yhm4vnWR/wCYPHf2k=', // You need to go to the development website to apply.

  // http
  baseURL: 'https://djibackend-dev.farmtune.de/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  websocketURL: 'wss://djibackend-dev.farmtune.de/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'

  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  rtmpURL: 'rtmp://djibackend-dev.farmtune.de/live/', // Example: 'rtmp://192.168.1.1/live/'
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: 'Please enter the server ip.',
  gbServerPort: 'Please enter the server port.',
  gbServerId: 'Please enter the server id.',
  gbAgentId: 'Please enter the agent id',
  gbPassword: 'Please enter the agent password',
  gbAgentPort: 'Please enter the local port.',
  gbAgentChannel: 'Please enter the channel.',
  // RTSP
  rtspUserName: 'Please enter the username.',
  rtspPassword: 'Please enter the password.',
  rtspPort: '8554',
  // Agora
  agoraAPPID: 'Please enter the agora app id.',
  agoraToken: 'Please enter the agora temporary token.',
  agoraChannel: 'Please enter the agora channel.',

  // map
  // You can apply on the AMap website.
  amapKey: 'Please enter the amap key.',
}
