import { LogLevel } from '@azure/msal-browser'

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_AZURE_CLIENT_ID,
    knownAuthorities: ['farmtune4dev.b2clogin.com'],
    authority: import.meta.env.VITE_AZURE_CLIENT_AUTHORITY,
    redirectUri:
      window.location.href.indexOf('pilot-login') !== -1
        ? import.meta.env.VITE_AZURE_REDIRECT_URI_PILOT
        : import.meta.env.VITE_AZURE_REDIRECT_URI, // pilot-login has to have a different redirect
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            break
          default:
        }
      },
      logLevel: LogLevel.Error,
    },
  },
}
