import { createI18n } from 'vue-i18n'
import de from './de.json'
import en from './en.json'

// Create VueI18n instance with options
export const i18n = createI18n({
  locale: 'de',
  legacy: false,
  fallbackLocale: 'de',
  globalInjection: true,
  messages: {
    de,
    en,
  },
})
