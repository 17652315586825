import App from './App.vue'
import router from './router'
import { antComponents } from './antd'
import { CommonComponents } from './use-common-components'
import 'virtual:svg-icons-register'
import store, { storeKey } from './store'
import { createInstance } from '/@/root'
import { useDirectives } from './directives'

import '/@/styles/index.scss'

import { i18n } from '/@/locales/lang'

import { initLoginFlow } from './login-flow'

sessionStorage.setItem('appMounted', 'false')
const authService = await initLoginFlow(store, router)

const app = createInstance(App)
app.provide('msalInstance', authService)
app.use(i18n)
app.use(store, storeKey)
app.use(router)
app.use(CommonComponents)
app.use(antComponents)
app.use(useDirectives)
app.mount('#farmtune-dji-cloud')
sessionStorage.setItem('appMounted', 'true')
