import { msalConfig } from './authConfig'
import {
  EventType,
  InteractionRequiredAuthError,
  PublicClientApplication,
  type AuthenticationResult,
} from '@azure/msal-browser'

import { FT4Token, Tenant } from '../model/baseTypes'

export class AuthService extends PublicClientApplication {
  callback: (
    isLoggedIn: boolean,
    tenant: any,
    token?: string,
    profile?: any,
    eventType?: string
  ) => void;

  constructor (
    newTokenCallback: (
      isLoggedIn: boolean,
      tenant?: any,
      token?: string,
      profile?: any,
      eventType?: string
    ) => void
  ) {
    super(msalConfig)
    this.callback = newTokenCallback
  }

  async initialize (): Promise<void> {
    await super.initialize()

    this.addEventCallback(async (event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload
      ) {
        const payload = event.payload as AuthenticationResult
        const account = payload.account
        this.setActiveAccount(account)
        this.callback(
          this.isLoggedIn(),
          this.getTenant(),
          this.getAccessToken(),
          this.getUserProfileFromToken(),
          event.eventType
        )
      } else if (event.eventType === EventType.LOGOUT_START) {
        // resets data in case of logout to speed up the checks (happening after the first redirect) to start the (2nd and final) redirect to the login page earlier
        this.setActiveAccount(null)
        this.callback(
          false,
          undefined,
          undefined,
          undefined,
          EventType.LOGOUT_START
        )
        this.logoutRedirect()
      }
    })
    this.callback(
      this.isLoggedIn(),
      this.getTenant(),
      this.getAccessToken(),
      this.getUserProfileFromToken(),
      'initialize'
    )
  }

  getTokenClaims (): FT4Token | undefined {
    return this.getActiveAccount()?.idTokenClaims as FT4Token
  }

  /**
   *
   * @returns true if according to MSAL definition user is logged in
   * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/d46c4455243bd51767f669a13fbb717d786c6716/samples/msal-angular-v2-samples/angular11-sample-app/src/app/home/home.component.ts#L33
   */
  isLoggedIn (): boolean {
    return this.getAllAccounts().length > 0
  }

  isAccessTokenValid (): boolean {
    const token = this.getTokenClaims()
    const expiresAt = token?.exp
    const currentTime = new Date().getTime() / 1000

    return expiresAt ? expiresAt > currentTime : false
  }

  async getTokenSilent (
    getWithTenantID: string = ''
  ): Promise<AuthenticationResult | boolean> {
    try {
      console.log(
        'TEST LOG >>',
        this.getActiveAccount(),
        getWithTenantID !== ''
          ? getAcquireContentWithTenantID(getWithTenantID)
          : loginRequest
      )

      const accessTokenResponse = await this.acquireTokenSilent(
        getWithTenantID !== ''
          ? getAcquireContentWithTenantID(getWithTenantID)
          : loginRequest
      )
      this.callback(this.isLoggedIn(), this.getTenant(), this.getAccessToken())
      return accessTokenResponse
    } catch (error) {
      // Acquire token silent failure, and send an interactive request
      if (error instanceof InteractionRequiredAuthError) {
        try {
          const accessTokenResponsePopup = await this.acquireTokenPopup(
            getWithTenantID !== ''
              ? getAcquireContentWithTenantID(getWithTenantID)
              : loginRequest
          )
          // Acquire token interactive success
          this.callback(
            this.isLoggedIn(),
            this.getTenant(),
            this.getAccessToken()
          )
          return accessTokenResponsePopup
        } catch (error) {
          // Acquire token interactive failure
          console.info('Issues opening authentication popup. Trying re-direct')
          const accessTokenResponse = await this.acquireTokenRedirect(
            getWithTenantID !== ''
              ? getAcquireContentWithTenantID(getWithTenantID)
              : loginRequest
          )
          // Acquire token interactive success
          this.callback(
            this.isLoggedIn(),
            this.getTenant(),
            this.getAccessToken()
          )
        }
      }
      console.warn(error)
      return false
    }
  }

  async ensureAccessToken () {
    if (this.getTokenClaims() === undefined) {
      // TODO: check if faster redirect is possible
    }

    const isTokenValid = this.isAccessTokenValid()
    if (!isTokenValid) {
      const lastUsedTenantID = window.localStorage.getItem('ft4_tenant_id')

      await this.getTokenSilent(
        lastUsedTenantID != null ? lastUsedTenantID : undefined
      )

      this.callback(
        this.isLoggedIn(),
        this.getTenant(),
        this.getAccessToken(),
        this.getUserProfileFromToken()
      )
    }
  }

  getUserProfileFromToken (): any | undefined {
    const activeAccount = this.getActiveAccount()?.idTokenClaims!
    if (!activeAccount) return undefined

    const newProfileData: any = {
      givenName: activeAccount.given_name as string | undefined,
      familyName: activeAccount.family_name as string | undefined,
      city: activeAccount.city as string | undefined,
      country: activeAccount.country as string | undefined,
      street: activeAccount.street_address as string | undefined,
      zipCode: activeAccount.postal_code as string | undefined,
      displayName: activeAccount.displayName as string | undefined,
    }

    if (!newProfileData.displayName) {
      newProfileData.displayName =
        activeAccount.given_name + ' ' + activeAccount.family_name
    }

    return newProfileData
  }

  /**
   *
   * @returns Current Access-Token of active account
   */
  getAccessToken (): string | undefined {
    return this.getActiveAccount()?.idToken
  }

  getTenant (): Tenant | undefined {
    const claims = this.getTokenClaims()?.ft4_tenant_id
    const newTenant = { id: claims, tenantId: claims } as Tenant
    return claims ? newTenant : undefined
  }
}

/// ///////////////////////
// request types
/// ///////////////////////

const loginRequest = {
  scopes: [],
  prompt: 'login',
}

function getAcquireContentWithTenantID (tenantID: string) {
  return {
    scopes: [],
    tokenQueryParameters: {
      tenantId: tenantID,
    },
  }
}
