<template>
  <div class="farmtune-dji-cloud">
    <router-view />
    <!-- <div class="map-wrapper">
      <GMap/>
    </div> -->
  </div>
</template>

<script setup lang="ts">
// import GMap from '/@/components/GMap.vue'

import { inject } from 'vue'
import { AuthService } from '/@/auth/authService'
import { useMyStore } from '/@/store'
const store = useMyStore()
const authService: AuthService = inject('msalInstance')!
store.commit('SET_MSAL_INSTANCE', authService)
</script>

<style lang="scss" scoped>
.farmtune-dji-cloud {
  width: 100%;
  height: 100%;

  .map-wrapper {
    height: 100%;
    width: 100%;
  }
}
</style>

<style lang="scss">
#farmtune-dji-cloud {
  width: 100%;
  height: 100%;
}

.vc-switch {
  right: 56px !important;
  bottom: 24px !important;
}
</style>
