import { ELocalStorageKey, EComponentName, EUserType, ERouterName } from '/@/types'
import { EventType } from '@azure/msal-browser'
import { type Router } from 'vue-router'
import { loginAD, refreshToken } from '/@/api/manage'
import { AuthService } from '/@/auth/authService'
import apiPilot from '/@/api/pilot-bridge'
import { CURRENT_CONFIG } from '/@/api/http/config'
import { message } from 'ant-design-vue'

function verifyLicense (): boolean {
  const isVerified =
    apiPilot.platformVerifyLicense(CURRENT_CONFIG.appId, CURRENT_CONFIG.appKey, CURRENT_CONFIG.appLicense) && apiPilot.isPlatformVerifySuccess()
  if (isVerified) {
    // message.success('The license verification was successful.')
  } else {
    message.error('Failed to verify the license. Please check whether the license is correct, or try again.')
  }
  return isVerified
}

async function doPilotSpecificLoginFlow (store: any, router: Router) {
  const isVerified = verifyLicense()
  if (!isVerified) {
    return
  }

  apiPilot.setPlatformMessage(store.getters.getUserName + '@Cloud Api Platform', '', '')
  const res = await refreshToken({}).catch((err) => {
    message.error('acquiring new token failed: ' + err)
  })

  apiPilot.setComponentParam(EComponentName.Api, {
    host: CURRENT_CONFIG.baseURL,
    token: res.data.access_token
  })
  const jsres = apiPilot.loadComponent(EComponentName.Api, apiPilot.getComponentParam(EComponentName.Api))
  if (!jsres) {
    message.error('Failed to load api module.')
    return
  }
  apiPilot.setToken(res.data.access_token)
  localStorage.setItem(ELocalStorageKey.Token, res.data.access_token)
  if (sessionStorage.getItem('appMounted') !== 'false') {
    router.push(ERouterName.PILOT_HOME)
  } else {
    setTimeout(() => {
      router.push(ERouterName.PILOT_HOME)
    }, 200)
  }
}

function setLoginResultsToLocalStorage (result: any) {
  localStorage.setItem(ELocalStorageKey.Token, result.data.access_token)
  localStorage.setItem(ELocalStorageKey.WorkspaceId, result.data.workspace_id)
  localStorage.setItem(ELocalStorageKey.Username, result.data.username)
  localStorage.setItem(ELocalStorageKey.UserId, result.data.user_id)
  localStorage.setItem(ELocalStorageKey.Flag, EUserType.Web.toString())
}

export async function initLoginFlow (store: any, router: Router): Promise<AuthService> {
  localStorage.setItem('localKeysInit', 'false')
  localStorage.setItem('regularLoginTriggered', 'false')
  localStorage.setItem('pilotSpecificLoginTriggered', 'false')

  const authService = new AuthService(async (isLoggedIn, tenant, token, profile, eventType) => {
    console.info('authService callback', isLoggedIn, tenant, token, profile, eventType)
    // to make sure logout-events are ignored here
    if (
      eventType === EventType.LOGOUT_START ||
      eventType === EventType.LOGOUT_END ||
      eventType === EventType.LOGOUT_SUCCESS ||
      eventType === EventType.LOGOUT_FAILURE
    ) {
      console.log('authService returning because of eventType', eventType)
      return
    }
    if (!isLoggedIn || !profile) {
      console.info('authService redirect !!')
      authService.loginRedirect()
      return
    }

    if (token) {
      store.dispatch('updateUserName', token)
    }

    console.info('auth flow: before checking localKeysInit (1)')
    if (localStorage.getItem('localKeysInit') === 'false') {
      const accessToken = authService.getAccessToken()
      console.info('auth flow: before checking accessToken !== undefined (2)')
      if (accessToken !== undefined) {
        localStorage.setItem('localKeysInit', 'true')
        const result = await loginAD(accessToken) // resulting data>>access_token is used as the new accessToken for further requests
        console.info('auth flow: before checking result.code === 0 (3)')
        if (result.code === 0) {
          setLoginResultsToLocalStorage(result)
          console.info(
            'auth flow: before checking window.location.href.indexOf("pilot-login") !== -1 OR ...indexOf("project") !== -1  (4) with a current location.href of',
            window.location.href
          )

          // regular login or pilot login:
          if (window.location.href.indexOf('pilot-login') !== -1) {
            localStorage.setItem('pilotSpecificLoginTriggered', 'true')
            await doPilotSpecificLoginFlow(store, router)
          } else if (
            window.location.href.indexOf('members') === -1 &&
            (window.location.href.indexOf('project') !== -1 || window.location.href === import.meta.env.VITE_AZURE_BASE_URI)
          ) {
            console.info('auth flow: before router.push to members (5)')
            localStorage.setItem('regularLoginTriggered', 'true')
            if (sessionStorage.getItem('appMounted') !== 'false') {
              router.push(ERouterName.MEMBERS)
              console.info('auth flow: before router.push to members done (6)')
            } else {
              setTimeout(() => {
                router.push(ERouterName.MEMBERS)
                console.info('auth flow: before router.push to members done (6; after timeout)')
              }, 200)
            }
          }
        } else {
          localStorage.setItem('localKeysInit', 'false')
          message.error(result.message)
        }
      }
    }
    store.dispatch('updateAccess', { isLoggedIn, tenant, token, profile })
  })

  await authService.initialize()
  await authService.handleRedirectPromise()
  await authService.ensureAccessToken()

  return authService
}
